"use client";



// Fix browser default selection boxes appearing on feature boxes when clicking,
// compare this implementation to the template to see how they did it (TailwindCSS/TailwindUI, React)




import React, { Fragment, useEffect, useRef, useState } from "react";
import { Tab } from "@headlessui/react";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { useDebouncedCallback } from "use-debounce";

import CustomData from "../assets/customdata.png";
import Insights from "../assets/insights.png";
import Chapters from "../assets/chapters.png";

import CustomDataIcon from "../assets/icons/CustomDataIcon.svg";
import InsightsIcon from "../assets/icons/InsightsIcon.svg";
import ChaptersIcon from "../assets/icons/ChaptersIcon.svg";

const features = [
  {
    name: "Track anything with custom data types",
    description:
      "Track over 200 Health app data types or create custom ones to track anything all in one place. Stay organized and have all your data easily accessible.",
    image: CustomData,
    icon: CustomDataIcon,
  },
  {
    name: "Intelligent insights",
    description:
      "Receive automatic alerts about key trends, changes, and data relationships. Get actionable insights that help you to stay on track with your goals.",
    image: Insights,
    icon: InsightsIcon,
  },
  {
    name: "See how circumstances affect your health with chapters",
    description:
      "Use chapters to track health events and understand their impact through data visualization. Discover how life events influence your health and well-being.",
    image: Chapters,
    icon: ChaptersIcon,
  },
];

const bodyAnimation = {
  initial: (custom) => ({
    opacity: 0,
    y: custom.isForwards ? 50 : -50,
    transition: { duration: 0.3, ease: "easeOut" }, // Make sure this comma is here
  }),
  animate: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.3, ease: "easeOut" }, // This also needs a comma
  },
  exit: (custom) => ({
    opacity: 0,
    y: custom.isForwards ? -50 : 50,
    transition: { duration: 0.3, ease: "easeIn" }, // Ensure this comma exists
  }),
};

function FeatureScreen({ image, custom }) {
  return (
    <motion.div
      variants={bodyAnimation}
      initial="initial"
      animate="animate"
      exit="exit"
      custom={custom}
      className="flex justify-center"
    >
      <img src={image} alt="Feature" className="w-full max-w-[400px] h-auto" />
    </motion.div>
  );
}

function usePrevious(value) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

function FeaturesDesktop() {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const prevIndex = usePrevious(selectedIndex);
  const isForwards = prevIndex === undefined ? true : selectedIndex > prevIndex;

  const onChange = useDebouncedCallback(
    (index) => {
      setSelectedIndex(index);
    },
    100,
    { leading: true }
  );

  return (
    <Tab.Group
      selectedIndex={selectedIndex}
      onChange={onChange}
      className="grid grid-cols-12 items-center gap-8 lg:gap-16 xl:gap-24"
      vertical
    >
      {/* Tab List */}
      <Tab.List className="relative z-10 order-last col-span-6 space-y-6">
        {features.map((feature, featureIndex) => (
          <div
            key={feature.name}
            className="relative rounded-2xl transition-colors hover:bg-gray-800/30"
          >
            {/* Active Background */}
            {featureIndex === selectedIndex && (
              <motion.div
                layoutId="activeBackground"
                className="absolute inset-0 bg-gray-800"
                initial={{ borderRadius: 16 }}
              />
            )}
            {/* Tab Content */}
            <div className="relative z-10 p-8">
              <img src={feature.icon} alt="" className="h-8 w-8 invert" />
              <h3 className="mt-6 text-lg font-semibold text-white select-none">
                <Tab className="text-left ui-not-focus-visible:outline-none select-none">
                  <span className="absolute inset-0 rounded-2xl" />
                  {feature.name}
                </Tab>
              </h3>
              <p className="mt-2 text-sm text-gray-400 select-none">
                {feature.description}
              </p>
            </div>
          </div>
        ))}
      </Tab.List>

      {/* Feature Image */}
      <div className="relative col-span-6">
        {/* Optional Background Component */}
        {/* <YourBackgroundComponent /> */}
        <div className="z-10 mx-auto w-full max-w-[366px]">
          <Tab.Panels as={Fragment}>
            <AnimatePresence mode="wait" initial={false} custom={{ isForwards }}>
              {features.map((feature, featureIndex) =>
                selectedIndex === featureIndex ? (
                  <Tab.Panel
                    static
                    key={feature.name}
                    className="col-start-1 row-start-1 flex focus:outline-offset-[32px] ui-not-focus-visible:outline-none"
                  >
                    <FeatureScreen
                      image={feature.image}
                      custom={{ isForwards }}
                    />
                  </Tab.Panel>
                ) : null
              )}
            </AnimatePresence>
          </Tab.Panels>
        </div>
      </div>
    </Tab.Group>
  );
}

function FeaturesMobile() {
  const [activeIndex, setActiveIndex] = useState(0);
  const slideContainerRef = useRef(null);
  const slideRefs = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting) {
            setActiveIndex(slideRefs.current.indexOf(entry.target));
            break;
          }
        }
      },
      {
        root: slideContainerRef.current,
        threshold: 0.6,
      }
    );

    slideRefs.current.forEach((slide) => {
      if (slide) observer.observe(slide);
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <>
      <div
        ref={slideContainerRef}
        className="-mb-4 flex snap-x snap-mandatory -space-x-4 overflow-x-auto overscroll-x-contain scroll-smooth pb-4 scrollbar-hide sm:-space-x-6"
      >
        {features.map((feature, index) => (
          <div
            key={index}
            ref={(ref) => ref && (slideRefs.current[index] = ref)}
            className="w-full flex-none snap-center px-4 sm:px-6"
          >
            <div className="relative transform overflow-hidden rounded-2xl bg-gray-800 px-5 py-6">
              {/* Removed CircleBackground */}
              {/* Replaced PhoneFrame and <feature.screen /> with your image */}
              <div className="relative mx-auto w-full max-w-[366px]">
                <img
                  src={feature.image}
                  alt="Feature"
                  className="w-full h-auto object-contain"
                />
              </div>
              {/* Overlay text at the bottom */}
              <div className="absolute inset-x-0 bottom-0 bg-gray-800/95 p-6 backdrop-blur sm:p-10">
                {/* Render feature.icon */}
                {typeof feature.icon === 'function' ? (
                  <feature.icon className="h-8 w-8" />
                ) : (
                  <img src={feature.icon} alt="" className="h-8 w-8 invert" />
                )}
                <h3 className="mt-6 text-sm font-semibold text-white sm:text-lg">
                  {feature.name}
                </h3>
                <p className="mt-2 text-sm text-gray-400">
                  {feature.description}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
      {/* Navigation dots */}
      <div className="mt-6 flex justify-center gap-3">
        {features.map((_, index) => (
          <button
            key={index}
            className={clsx(
              'relative h-0.5 w-4 rounded-full',
              index === activeIndex ? 'bg-gray-300' : 'bg-gray-500'
            )}
            aria-label={`Go to slide ${index + 1}`}
            onClick={() => {
              slideRefs.current[index]?.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'center',
              });
            }}
          >
            <span className="absolute -inset-x-1.5 -inset-y-3" />
          </button>
        ))}
      </div>
    </>
  );
}

export function PrimaryFeatures() {
  return (
    <section id="features" aria-label="Features" className="bg-gray-900 py-16 sm:py-24">
      <div className="max-w-7xl mx-auto px-4 h-full">
        {/* Header text left-aligned along the left side of the screen */}
        <div className="relative z-10 text-left">
        <h2 className="text-4xl font-extrabold tracking-tight text-white">
  Features that make your data <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-cyan-500 decoration-blue-500/50">useful</span>
</h2>
          <p className="mt-4 text-lg text-gray-400">
            {/* Replace with your description */}
            Coefficients helps you find actionable insights for optimizing your
            health and fitness using any of the over 220 supported data types from the Health app in addition to your own custom data types.
          </p>
        </div>
        <div className="mt-16 md:hidden">
          <FeaturesMobile />
        </div>
        <div className="hidden md:mt-20 md:block">
          <FeaturesDesktop />
        </div>
      </div>
    </section>
  );
}
