import React from 'react';

const Privacy = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-white to-gray-50">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 pt-32 pb-16">
        <div className="text-center mb-16">
          <h1 className="text-4xl font-black text-gray-900 mb-4">Privacy Policy</h1>
          <p className="text-gray-500">Last Updated February 27, 2025</p>
        </div>

        <div className="prose prose-lg max-w-none">
          <div className="bg-white rounded-2xl shadow-sm p-8 mb-8">
            <p className="text-gray-600 leading-relaxed mb-8">
              Coefficients is committed to protecting your privacy. Given the sensitive nature of your health data, 
              we are mindful of how we protect and utilize your information when you use the app. This privacy policy 
              may change over time as new features and capabilities are introduced.
            </p>

            <div className="space-y-12">
              <section>
                <h2 className="text-2xl font-bold text-gray-900 mb-4">Data Collection and Usage</h2>
                <p className="text-gray-600 leading-relaxed">
                  Coefficients requests access to your HealthKit data only when needed, and you can revoke access at any time. 
                  Custom data types and stored data points are saved in a local, encrypted database, accessible only by you. 
                  Goal completion percentages are stored securely in the on-device database for future reference when a goal is completed. 
                  Completion percentages are calculated as a given day's value for the goal data type as a percentage of the goal / target value.
                  We do not copy or store any of your health data on remote databases, servers, 3rd-party cloud services, or iCloud. 
                  We collect no user data, and by extension never request or store any personally identifiable information.
                </p>
              </section>

              <section>
                <h2 className="text-2xl font-bold text-gray-900 mb-4">Data Export and Control</h2>
                <p className="text-gray-600 leading-relaxed">
                  You can export any of your custom data types and their data points to a CSV file at any time, 
                  giving you full control over your data. Once exported, you can store or manipulate this data any way you wish. 
                  This can be useful for creating backups, as your custom data is not stored anywhere else and will be lost 
                  permanently if your iOS device is factory reset, destroyed, or if the app is deleted. However, please note that the exported 
                  file is not encrypted and should be stored securely.
                </p>
              </section>

              <section>
                <h2 className="text-2xl font-bold text-gray-900 mb-4">Security</h2>
                <p className="text-gray-600 leading-relaxed">
                  Coefficients encrypts the database stored locally on your device using AES-256, which applies 
                  Advanced Encryption Standard (AES) with a 256-bit key length. Coefficients generates a 512-bit 
                  encryption key when you first set up the app. This key is securely stored in the iOS Keychain, 
                  ensuring that no other app or process can access it. Depending on your device settings, this key 
                  may be stored to iCloud. Your data is inaccessible to anyone without this key.
                </p>
              </section>

              <section>
                <h2 className="text-2xl font-bold text-gray-900 mb-4">Contact Us</h2>
                <p className="text-gray-600 leading-relaxed">
                  For questions about this Privacy Policy, or anything else, please contact us at{' '}
                  <a 
                    href="mailto:team@coefficients.io" 
                    className="text-blue-600 hover:text-blue-800 font-semibold transition-colors"
                  >
                    team@coefficients.io
                  </a>
                </p>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacy; 